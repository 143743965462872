import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import axios from 'axios'

export class ApplicationRole {
  id = '' // path without permission, without language, eg : /processingform, or /marketedMilks/findfarmermilk
  nameDe = '' // read | write | anything
  nameFr = '' // read | write | anything
  nameIt = '' // read | write | anything
}

import { defineStore } from 'pinia'

export const useApplicationRolesStore = defineStore('ApplicationRolesStore', {
  state: () => ({
    ApplicationRoles: [] as ApplicationRole[]
  }),
  actions: {
    async fetchApplicationRoles() {
      if (this.ApplicationRoles.length == 0) {
        try {
          const result = await axios.get(apiURL + '/applicationRoles')
          this.ApplicationRoles = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
