<template>
  <div>
    <h2 v-html="$t('Search_ApplicationUser_Title')"></h2>
    <v-text-field
      id="search_ApplicationUser_agateId"
      v-model="SearchApplicationUserStore.ApplicationUser_agateId"
      :label="$t('Search_ApplicationUser_agateId')"
      type="number"
      @keyup.enter="search"
    />
    <v-select
      id="search_ApplicationUser_applicationRoleId"
      v-model="SearchApplicationUserStore.ApplicationUser_applicationRoleId"
      :label="$t('ApplicationUser_applicationRoleId')"
      :items="applicationRoles"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <v-text-field
        id="search_ApplicationUser_email"
        v-model="SearchApplicationUserStore.ApplicationUser_email"
        :label="$t('ApplicationUser_email')"
        type="email"
        @keyup.enter="search"
      />
      <v-select
        id="search_ApplicationUser_status"
        v-model="SearchApplicationUserStore.ApplicationUser_status"
        :label="$t('ApplicationUser_status')"
        :items="ApplicationUser_status"
        item-title="text"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import baseSearchVue from './baseSearch.vue'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'

import { useApplicationRolesStore } from '@/store/enums/ApplicationRolesStore'

export default defineComponent({
  name: 'searchApplicationUser',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      ApplicationUser_status: [
        { id: 1, text: this.$t('search_applicationUser_active') },
        { id: 0, text: this.$t('search_applicationUser_not_active') }
      ]
    }
  },
  computed: {
    SearchApplicationUserStore() {
      return useSearchApplicationUserStore()
    },
    applicationRoles() {
      return useApplicationRolesStore().ApplicationRoles
    }
  },
  methods: {
    reset() {
      this.resetFields(this.SearchApplicationUserStore)
    }
  },
  mounted() {
    useApplicationRolesStore().fetchApplicationRoles()
    this.initSearchStoreFromQuery(this.SearchApplicationUserStore, this.$route.query)
  }
})
</script>
